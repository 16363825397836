import * as React from 'react';
import SignOutCard from '../components/SignOutCard';
import NavBarData from '../components/NavBarData';

const SignOutpage: React.FunctionComponent<{

}> = (props) => {
    return(
        <div>
            <NavBarData />
            <SignOutCard />
        </div>
    );
}

export default SignOutpage;