import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import LoginForm from './LoginForm';
import VerifySignUpFormData from './VerifySignUpFormData';
import { Redirect } from 'react-router-dom';

type LoginDataState = {
    username: string,
    password: string,
    loggedIn: boolean,
    errorMessage: string,
    verify: boolean
}

type LoginDataProps = {

}

export default class LoginFormData extends Component<LoginDataProps, LoginDataState> {
    constructor(props: LoginDataProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            loggedIn: false,
            errorMessage: "",
            verify: false
        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleUsernameField = this.handleUsernameField.bind(this);
        this.handlePasswordField = this.handlePasswordField.bind(this);
        this.handleVerifyReset = this.handleVerifyReset.bind(this);
    }

    handleLogin = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            const user = await Auth.signIn(this.state.username, this.state.password);
            console.log(user);
            this.setState({
                loggedIn: true,
                errorMessage: ""
            });
        } catch (err) {
            console.log({ err });
            if (err.code === "UserNotConfirmedException") {
                this.setState({
                    verify: true
                })
            }
            else {
                this.setState({
                    errorMessage: err.message
                })
            }
        }
    }

    handleUsernameField = (event:React.ChangeEvent<any>) => {
        this.setState({
            username: event.currentTarget.value
        })
    }

    handlePasswordField = (event:React.ChangeEvent<any>) => {
        this.setState({
            password: event.currentTarget.value
        })
    }

    handleVerifyReset = () => {
        this.setState({
            verify: false
        })
    }

    render() {
        if (this.state.loggedIn) {
            return <Redirect push to="/" />
        }
        if (this.state.verify) {
            return(
                <VerifySignUpFormData username={ this.state.username } handleVerifyReset={ this.handleVerifyReset }/>
            )
        }
        return(
            <LoginForm handleLogin={ this.handleLogin } handleUsername={ this.handleUsernameField } handlePassword={ this.handlePasswordField } errorMessage={ this.state.errorMessage }/>
        )
    }

}