import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Link } from 'react-router-dom';
import NavBarData from '../components/NavBarData';

const NotFoundPage: React.FunctionComponent<{

}> = (props) => {
    return(
        <div>
            <NavBarData />
            <Container>
                <Jumbotron className="text-center">
                    <h1>404 Page Not Found</h1>
                    <p>Sorry, we are not able to find the page you are looking for.</p>
                    <Link to="/">Return to Home</Link>
                </Jumbotron>
            </Container>
        </div>
    )
}

export default NotFoundPage;