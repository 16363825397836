import * as React from 'react';
import axios from 'axios';
import { Content }from '../common/types';
import EditableContentItem from './EditableContentItem';
import { Auth } from 'aws-amplify';
import config from '../config';

type EditableContentItemDataState = {
    deleteConfirm: boolean;
    likeCount: number;
    error: string;
    deleteError: string;
}

type EditableContentItemDataProps = {
    content: Content;
    reloadList: () => void;
}

export default class EditableContentItemData extends React.Component<EditableContentItemDataProps, EditableContentItemDataState> {
    constructor(props: EditableContentItemDataProps) {
        super(props);

        this.state = {
            deleteConfirm: false,
            likeCount: 0,
            error: "",
            deleteError: ""
        };

        this.handleConfirmDeleteShow = this.handleConfirmDeleteShow.bind(this);
        this.handleContentItemDeletion = this.handleContentItemDeletion.bind(this);
    }

    componentDidMount() {
        // Get number of likes for a content
        axios({
            url: `${ config.api.SERVER_URL }/likes/content/count?id=${ this.props.content.id }`,
            method: 'get'
        }).then(res => {
            this.setState({
                likeCount: res.data.count
            })
        })
    }

    handleConfirmDeleteShow = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({
            deleteConfirm: !this.state.deleteConfirm
        })
    }

    handleContentItemDeletion = async (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const currSess = await Auth.currentSession();
        axios({
            method: 'delete',
            url: `${ config.api.SERVER_URL }/content/author/${this.props.content.author}/${this.props.content.title}`,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then((response) => {
            this.setState({
                deleteConfirm: false
            })
            this.props.reloadList()
        }).catch(error => {
            this.setState({
                deleteError: "Unable to delete content. Please try again at a later time."
            });
        }); 
    }

    render() {
        return(
            <EditableContentItem content={ this.props.content }
                likeCount={ this.state.likeCount }
                deleteConfirm={ this.state.deleteConfirm }
                deleteError={ this.state.deleteError }
                handleConfirmDeleteShow={ this.handleConfirmDeleteShow }
                handleContentItemDeletion={ this.handleContentItemDeletion }
            />
        );
    }
}