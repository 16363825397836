import * as React from 'react';
import ContentData from '../components/ContentData';
import NavBarData from '../components/NavBarData';
import LandingPageJumbotron from '../components/LandingPageJumbotron';
import { ContentLoadPath, ViewMorePath } from '../common/types';
import RandomGenreContainerData from '../components/RandomGenreContainerData';

const LandingPage: React.FunctionComponent<{

}> = (props) => {
    return(
        <div>
            <NavBarData />
            <LandingPageJumbotron />
            <ContentData title="Latest" contentLoadPath={ ContentLoadPath.Latest } size={ 4 } viewMorePath={ ViewMorePath.Latest }/>
            <RandomGenreContainerData numberOfGenres={ 2 } />
            <ContentData title="Content" contentLoadPath={ ContentLoadPath.Content } size={ 4 } viewMorePath={ ViewMorePath.Content }/>
        </div>
    );
}

export default LandingPage;