import * as React from 'react'
import { SavedContentItem } from '../common/types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContentIDItemData from './ContentIDItemData'
import Pagination from 'react-bootstrap/Pagination'

const ContentIDList: React.FunctionComponent<{
    savedContentList: SavedContentItem[]
    page: number
    pageCount: number
    handlePageChange: (event: any) => void
    handleContentNotFound: (id: string) => void
}> = (props) => {
    let paginationItems = [];
    let nextDisabled = false;
    let prevDisabled = false;

    // Load all pagination pages if 7 or less pages
    if (props.pageCount < 8) {
        for (let number = 0; number < props.pageCount; number++) {
            paginationItems.push(
                <Pagination.Item
                    key={ number }
                    active={ number === Number(props.page)}
                >
                    { number }
                </Pagination.Item>
            )
        }
    }
    // Determine where to put ellipse
    else {
        let pageNumbers:number[] = [];
        //Create array of pages to load
        if (Number(props.page) < 4 ) {
            pageNumbers = [0, 1, 2, 3, 4,  -1, Number(props.pageCount) - 1]
        }
        else if (Number(props.page) > Number(props.pageCount) - 5) {
            pageNumbers = [0, -1, Number(props.pageCount) - 5, Number(props.pageCount) - 4, Number(props.pageCount) - 3,  Number(props.pageCount) - 2, Number(props.pageCount) - 1]
        }
        else {
            pageNumbers = [0, -1, Number(props.page) - 1, Number(props.page), Number(props.page) + 1, -1, Number(props.pageCount) - 1]
        }
        pageNumbers.forEach( (pageItem) => {
            if (pageItem !== -1) {
                paginationItems.push(
                    <Pagination.Item
                        key={ pageItem }
                        active={ pageItem === Number(props.page) }
                    >
                        { pageItem }
                    </Pagination.Item>
                )
            }
            else {
                paginationItems.push(
                    <Pagination.Ellipsis disabled />
                )
            }
        })
    }

    if (Number(props.page) === 0) {
        prevDisabled = true;
    }
    if (Number(props.page) === props.pageCount - 1) {
        nextDisabled = true;
    }
    return(
        <Container fluid>
            <Row>
                {props.savedContentList.map(savedContent => (
                    <Col xs="6" sm="4" md="3" lg="2" key={ savedContent.content_id }>
                        <ContentIDItemData
                          key={ savedContent.content_id }
                          id={ savedContent.content_id }
                          handleContentNotFound={ props.handleContentNotFound }
                        />
                    </Col>
                ))}
            </Row>
            <Pagination
                className="justify-content-center"
                onClick={ props.handlePageChange }
            >
                <Pagination.Prev disabled={ prevDisabled }
                />
                { paginationItems }
                <Pagination.Next disabled={ nextDisabled }
                />
            </Pagination>
        </Container>
    )
}

export default ContentIDList