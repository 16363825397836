import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { NavLink } from 'react-router-dom';

const PasswordChangeSuccessfulCard: React.FunctionComponent<{

}> = (props) => {
    return(
        <Container>
            <Card className="justify-content-center">
                <Card.Body>
                    <Card.Title>Successfully Changed Password</Card.Title>
                    <NavLink to="/login">Return to Login Page</NavLink>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default PasswordChangeSuccessfulCard;