import * as React from 'react';
import axios from 'axios';
import AuthorEditForm from './AuthorEditForm';
import { User } from '../common/types';
import { Auth } from 'aws-amplify';
import config from '../config';
import { Redirect } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

type AuthorEditFormState = {
    user: User;
    cancelConfirm: boolean;
    submitted: boolean;
    hasChanges: boolean;
    error: string;
    submitError: string;
}

type AuthorEditFormProps = {

}

export default class AuthorEditFormData extends React.Component<AuthorEditFormProps, AuthorEditFormState> {
    constructor(props: AuthorEditFormProps) {
        super(props)

        this.state = {
            user: {
                username: "",
                description: "",
                picURL: ""
            },
            cancelConfirm: false,
            submitted: false,
            hasChanges: false,
            error: "",
            submitError: ""
        }

        this.handleDescriptionField = this.handleDescriptionField.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleCancelConfirm = this.handleCancelConfirm.bind(this);
    }

    async componentDidMount() {
        const currSess = await Auth.currentSession();
        axios({
            method: 'get',
            url: `${ config.api.SERVER_URL }/accounts/me`,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then((response) => {
            this.setState({
                user: response.data
            })
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            });
        });
    }

    shouldComponentUpdate() {
        return true
    }

    handleSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const currSess = await Auth.currentSession();
        let putBody = {
            ...this.state.user,
            description: this.state.user.description
        }
        axios({
            method: 'put',
            url: `${ config.api.SERVER_URL }/accounts/me`,
            data: putBody,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then((response) => {
            this.setState({
                submitted: true
            });
        }).catch(error => {
            this.setState({
                submitError: "Unable to submit edit. Please try again at a later time."
            });
        });
    }

    handleCancelButton = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.state.hasChanges) {
            this.setState(prevState => ({
                cancelConfirm: !prevState.cancelConfirm
            }))
        }
        else {
            this.setState({
                submitted: true
            })
        }
    }

    handleCancelConfirm = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({
            submitted: true
        })
    }

    handleDescriptionField = (event:React.ChangeEvent<any>) => {
        let tempUser = {
            ...this.state.user,
            description: event.currentTarget.value
        };
        this.setState({
            user: tempUser,
            hasChanges: true
        });
    }

    render() {
        // TODO: How to separately handle load error vs submit error
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        if (this.state.submitted) {
            return(
                <Redirect to="/myaccount" />
            )
        }
        return(
            <AuthorEditForm 
                username={ this.state.user.username }
                description={ this.state.user.description }
                cancelShow={ this.state.cancelConfirm }
                submitError={ this.state.submitError }
                handleDescriptionField={ this.handleDescriptionField } 
                handleSubmit={ this.handleSubmit }
                handleCancelButton={ this.handleCancelButton }
                handleCancelConfirm={ this.handleCancelConfirm }
            />
        )
    }
}