import * as React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Author } from '../common/types';
import blankAvatar from '../assets/blank-avatar.png';
import { Link } from 'react-router-dom';

const AuthorInfo: React.FunctionComponent<{
    author: Author;
    editable: boolean;
}> = (props) => {
    let avatar = blankAvatar;
    if (typeof props.author.picURL!='undefined' && props.author.picURL) {
        avatar = props.author.picURL;
    }

    return(
        <Jumbotron fluid >
            <Container fluid>
                <Row>
                    <Col xs="auto">
                        <Row>
                            <Col>
                                <Image src={ avatar }
                                roundedCircle
                                style={{ height: '8rem', width: '8rem' }}
                                test-selector="avatarAuthor"
                            />
                            </Col>
                        </Row>
                        {
                            props.editable ? 
                            <Row style={{ marginTop: '1rem'}}>
                                <Col style={{ display:"flex", justifyContent:"center" }}>
                                    <Link to="/myaccount/edit"
                                        test-selector="linkEdit"
                                    >
                                        Edit Profile
                                    </Link>
                                </Col>
                            </Row>
                            : null
                        }
                    </Col>
                    <Col>
                        <Container fluid>
                            <Row>
                                <h3 test-selector="nameAuthor">{ props.author.username }</h3>
                            </Row>
                            <Row>
                                <p test-selector="descriptionAuthor">
                                    { props.author.description }
                                </p>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    )
}

export default AuthorInfo;