import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';


const ForgotPasswordForm: React.FunctionComponent<{
    isUserSubmitted: boolean,
    submitPasswordErr: string,
    handleUsername: (event:React.ChangeEvent<any>) => void,
    handleUsernameSubmit: (event:React.FormEvent<HTMLFormElement>) => void,
    handleVerificationCode: (event:React.ChangeEvent<any>) => void,
    handleNewPassword: (event:React.ChangeEvent<any>) => void,
    handleConfirmPassword: (event:React.ChangeEvent<any>) => void,
    handlePasswordSubmit: (event:React.FormEvent<HTMLFormElement>) => void
}> = (props) => {
    return(
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title>Forgot Password Form</Card.Title>
                    { props.isUserSubmitted 
                        ? <NewPasswordForm submitPasswordErr={ props.submitPasswordErr }
                            handleVerificationCode={ props.handleVerificationCode }
                            handleNewPassword={ props.handleNewPassword}
                            handleConfirmPassword={ props.handleConfirmPassword }
                            handlePasswordSubmit={ props.handlePasswordSubmit }    
                        />
                        : <UserSubmitForm handleUsername={ props.handleUsername } 
                            handleUsernameSubmit={ props.handleUsernameSubmit }
                        />
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}

const UserSubmitForm: React.FunctionComponent<{
    handleUsername: (event:React.ChangeEvent<any>) => void,
    handleUsernameSubmit: (event:React.FormEvent<HTMLFormElement>) => void
}> = (props) => {
    return(
        <Form className="justify-content-center" onSubmit={ props.handleUsernameSubmit }>
            <Form.Group >
                <Form.Label>Username</Form.Label>
                <Form.Control type="username" onChange={ props.handleUsername }/>
            </Form.Group>
            
            <Button variant="primary" type="submit">
                Next
            </Button>
        </Form>
    )
}

const NewPasswordForm: React.FunctionComponent<{
    submitPasswordErr: string,
    handleVerificationCode: (event:React.ChangeEvent<any>) => void,
    handleNewPassword: (event:React.ChangeEvent<any>) => void,
    handleConfirmPassword: (event:React.ChangeEvent<any>) => void,
    handlePasswordSubmit: (event:React.FormEvent<HTMLFormElement>) => void
}> = (props) => {
    return(
        <Form className="justify-content-center" onSubmit={ props.handlePasswordSubmit }>
            <Alert variant="info">
                A code has been sent to this username's associated email. Please input it to change your password.
            </Alert>
            { props.submitPasswordErr 
                ?   <Alert variant="danger">{ props.submitPasswordErr }</Alert>
                : null
            }
            <Form.Group >
                <Form.Label>Code</Form.Label>
                <Form.Control type="text" onChange={ props.handleVerificationCode }/>
            </Form.Group>
            <Form.Group >
                <Form.Label>New Password</Form.Label>
                <Form.Control type="password" onChange={ props.handleNewPassword }/>
            </Form.Group>
            <Form.Group >
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" onChange={ props.handleConfirmPassword }/>
            </Form.Group>
            
            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    )
}

export default ForgotPasswordForm;