import * as React from 'react';
import { BaseContentItem, ViewMorePath} from '../common/types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StoryCardData from './StoryCardData';
import { Link } from 'react-router-dom';


const StoryList: React.FunctionComponent<{
    contents: BaseContentItem[];
    title: string;
    viewMorePath: string;
    isSaved: boolean
}> = (props) => {
    return(
        <Container fluid>
            <Row>
                <Col sm={11}>
                    <h2 test-selector={ `title${props.title}` }>{ props.title }</h2>
                </Col>
                <Col>
                    { props.viewMorePath !== ViewMorePath.None ?
                    <Link to={ props.viewMorePath } className="justify-content-end">View More</Link> 
                    : null }
                </Col>
            </Row>
            <Row>
                <Container fluid>
                    <Row>
                        {props.contents.map(content => (
                            <Col xs="6" sm="4" md="3" lg="2">
                                <StoryCardData
                                    content={ content }
                                    isSaved={ props.isSaved }
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Row>
        </Container>
    );
}

export default StoryList