import React, { Component } from 'react';
import axios from 'axios';
import { Content } from '../common/types';
import EditableContentList from './EditableContentList';
import Alert from 'react-bootstrap/Alert';
import config from '../config';

type EditableContentListDataState = {
    contentList: Content[];
    error: string;
}

type EditableContentListDataProps = {
    username: string;
}

export default class EditableContentListData extends Component<EditableContentListDataProps, EditableContentListDataState> {
    constructor(props: EditableContentListDataProps) {
        super(props);

        this.state = {
            contentList: [],
            error: ""
        }

        this.reloadList = this.reloadList.bind(this)
    }

    componentDidMount() {
        axios({
            url: `${ config.api.SERVER_URL }/content/author/${this.props.username}`,
            method: 'get'
        }).then(response => {
            console.log(response);
            this.setState({
                contentList: response.data
            });
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            });
        });
    }

    reloadList() {
        axios({
            url: `${ config.api.SERVER_URL }/content/author/${this.props.username}`,
            method: 'get'
        }).then(response => {
            console.log(response);
            this.setState({
                contentList: response.data
            });
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            });
        });
    }

    render() {
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        return(
            <EditableContentList contents={ this.state.contentList } reloadList={ this.reloadList }/>
        )
    }
}