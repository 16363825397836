import * as React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { Auth } from 'aws-amplify';
import PasswordChangeSuccessfulCard from './PasswordChangeSuccessfulCard';

type ForgotPasswordFormDataState = {
    username: string,
    newPassword: string,
    confirmPassword: string,
    verificationCode: string,
    isUsernameSubmitted: boolean,
    isPasswordSubmitted: boolean,
    submitPasswordErr: string
}

type ForgotPasswordFormDataProps = {

}

export default class ForgotPasswordFormData extends React.Component<ForgotPasswordFormDataProps, ForgotPasswordFormDataState> {
    constructor(props: ForgotPasswordFormDataProps) {
        super(props);

        this.state = {
            username: "",
            newPassword: "",
            confirmPassword: "",
            verificationCode: "",
            isUsernameSubmitted: false,
            isPasswordSubmitted: false,
            submitPasswordErr: ""
        }

        this.handleUsername = this.handleUsername.bind(this);
        this.handleUsernameSubmit = this.handleUsernameSubmit.bind(this);
        this.handleVerificationCode = this.handleVerificationCode.bind(this);
        this.handleNewPassword = this.handleNewPassword.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    }

    handleUsername = (event:React.ChangeEvent<any>) => {
        this.setState({
            username: event.currentTarget.value
        });
    }

    handleUsernameSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await Auth.forgotPassword(this.state.username);
            this.setState({
                isUsernameSubmitted: true
            });
        } catch (err) {
            console.log({ err });
        }
    }

    handleVerificationCode = (event:React.ChangeEvent<any>) => {
        this.setState({
            verificationCode: event.currentTarget.value
        })
    }

    handleNewPassword = (event:React.ChangeEvent<any>) => {
        this.setState({
            newPassword: event.currentTarget.value
        })
    }

    handleConfirmPassword = (event:React.ChangeEvent<any>) => {
        this.setState({
            confirmPassword: event.currentTarget.value
        })
    }

    handlePasswordSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // TODO: Compare new password and confirmed password field to make sure they are the same
        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({
                submitPasswordErr: "New Password and Confirm Password fields do not match."
            })
            return
        }
        try {
            await Auth.forgotPasswordSubmit(this.state.username, this.state.verificationCode, this.state.newPassword)
            this.setState({
                isPasswordSubmitted: true
            })
        } catch (err) {
            console.log({ err })
            this.setState({
                submitPasswordErr: err.message
            })
        }
    }

    render() {
        if (this.state.isPasswordSubmitted) {
            return(
                <PasswordChangeSuccessfulCard />
            )
        }
        return(
            <ForgotPasswordForm isUserSubmitted={ this.state.isUsernameSubmitted }
                handleUsername={ this.handleUsername }
                submitPasswordErr={ this.state.submitPasswordErr }
                handleUsernameSubmit={ this.handleUsernameSubmit }
                handleVerificationCode={ this.handleVerificationCode }
                handleNewPassword={ this.handleNewPassword }
                handleConfirmPassword={ this.handleConfirmPassword }
                handlePasswordSubmit={ this.handlePasswordSubmit }
            />
        )
    }
} 