import React, { Component } from 'react';
import VerifySignUpForm from './VerifySignUpForm';
import { Auth } from 'aws-amplify';
import alert from 'react-bootstrap/Alert';
import axios from 'axios';
import config from '../config';
import { Redirect } from 'react-router-dom';

type VerifyFormDataState = {
    verified: boolean,
    verification: string,
    verifyAlert: boolean,
    error: string
}

type VerifyFormDataProps = {
    username: string
    handleVerifyReset: () => void,
}

export default class VerifySignUpFormData extends Component<VerifyFormDataProps, VerifyFormDataState> {
    constructor(props: VerifyFormDataProps) {
        super(props)

        this.state = {
            verified: false,
            verification: "",
            verifyAlert: false,
            error: ""
        }

        this.handleVerification = this.handleVerification.bind(this);
        this.handleVerificationField = this.handleVerificationField.bind(this);
        this.handleResendVerification = this.handleResendVerification.bind(this);
        this.handleVerifyAlertClose = this.handleVerifyAlertClose.bind(this);
    }

    handleVerification = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await Auth.confirmSignUp(this.props.username, this.state.verification);
            let postBody = {
                "username": this.props.username
            };
            axios({
                method: 'post',
                url: `${ config.api.SERVER_URL }/author`,
                data: postBody
            }).then((response) => {
                this.setState({
                    verified: true,
                });
                this.props.handleVerifyReset()
            }).catch(error => {
                this.setState({
                    error: "Unable to verify account. Please try again at a later time."
                });
            });
        } catch (err) {
            if(err.code != null) {
                this.setState({
                    error: err.message
                });
            }
            else {
                this.setState({
                    error: "Unable to verify account. Please try again at a later time."
                });
            }
        }
    }

    handleResendVerification = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        try {
            await Auth.resendSignUp(this.props.username);
            this.setState({
                verifyAlert: true
            })
        } catch (err) {
            console.log({ err });
        }
    }

    handleVerificationField = (event:React.ChangeEvent<any>) => {
        this.setState({
            verification: event.currentTarget.value
        })
    }

    handleVerifyAlertClose = () => {
        this.setState({
            verifyAlert: false
        })
    }

    render() {
        if (this.state.verified) {
            return(
                <Redirect to="/login" />
            )
        }
        return(
            <VerifySignUpForm verifyError={ this.state.error }
                handleVerificationField={ this.handleVerificationField }
                handleVerification={ this.handleVerification }
                handleResendVerification={ this.handleResendVerification }
                handleVerifyAlertClose={ this.handleVerifyAlertClose }
                verifyShow={ this.state.verifyAlert }
                />
        )
    }
}