import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { APPROVED_GENRES, Content } from '../common/types';

const ContentItemUploadForm: React.FunctionComponent<{
    content: Content,
    contenBody: string,
    submitError: string,
    handleTitleField: (event:React.ChangeEvent<any>) => void,
    handleDescriptionField: (event:React.ChangeEvent<any>) => void,
    handleGenreField: (event:React.ChangeEvent<HTMLInputElement>) => void,
    handleContentField: (event:React.ChangeEvent<any>) => void,
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}> = (props) => {
    return(
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title>Create Content</Card.Title>
                    { props.submitError
                        ? <Alert variant={ 'danger' }>{ props.submitError }</Alert>
                        : null
                    }
                    <Form onSubmit={ props.handleSubmit }>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text"
                                defaultValue={ props.content.title }
                                onChange={ props.handleTitleField }
                                test-selector="contentTitle"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text"
                                defaultValue={ props.content.description }
                                onChange={ props.handleDescriptionField }
                                test-selector="contentDescription"
                            />
                        </Form.Group>
                        <Form.Label>Genre</Form.Label>
                        <Form.Group>
                            { APPROVED_GENRES.map((genre) => (
                                props.content.genre.includes(genre)
                                    ? <Form.Check
                                        inline 
                                        type='checkbox'
                                        id={ `checkbox-${ genre }` }
                                        label={ genre }
                                        name={ genre }
                                        checked
                                        onChange={ props.handleGenreField }
                                        test-selector={ `contentGenre${genre}` }
                                    />
                                    : <Form.Check
                                        inline 
                                        type='checkbox'
                                        id={ `checkbox-${ genre }` }
                                        label={ genre }
                                        name={ genre }
                                        onChange={ props.handleGenreField }
                                        test-selector={ `contentGenre${genre}` }
                                    />
                            ))}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Content</Form.Label>
                            <Form.Control as="textarea" 
                                rows={20}
                                onChange={ props.handleContentField }
                                defaultValue={ props.contenBody }
                                test-selector="contentText"
                            />
                        </Form.Group>

                        <Button variant="primary"
                            type="submit"
                            test-selector="contentSubmit"
                        >
                            Create
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default ContentItemUploadForm;