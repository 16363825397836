import React, { Component } from 'react';
import axios from 'axios';
import ContentItemUploadForm from './ContentItemUploadForm';
import { Content, AuthError } from '../common/types';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Redirect } from 'react-router-dom';
import config from '../config';

type ContentItemUpdateFormState = {
    content: Content;
    contentBody: string;
    session: any;
    authLoad: boolean;
    authError: AuthError;
    submitted: boolean;
    error: string;
    submitError: string;
}

type ContentItemUpdateFormProps = {
    author: string;
    title: string;
}

export default class ContentItemUpdateFormData extends Component<ContentItemUpdateFormProps, ContentItemUpdateFormState> {
    constructor(props: ContentItemUpdateFormProps) {
        super(props);

        this.state = {
            content: {
                id: "",
                author: "",
                title: "",
                description: "",
                genre: [],
                creation_date: 0,
                page_count: 0,
                body: []
            },
            contentBody: "",
            session: null,
            authLoad: false,
            authError: AuthError.None,
            submitted: false,
            error: "",
            submitError: ""
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTitleField = this.handleTitleField.bind(this);
        this.handleDescriptionField = this.handleDescriptionField.bind(this);
        this.handleGenreField = this.handleGenreField.bind(this);
        this.handleContentField = this.handleContentField.bind(this);
    }

    async componentDidMount() {
        let currSess:CognitoUserSession
        try {
            currSess = await Auth.currentSession()
            if (currSess == null || currSess.getAccessToken().payload["username"] !== this.props.author) {
                this.setState({
                    authError: AuthError.Forbidden
                })
            }
            this.setState({
                session: currSess,
                authLoad: true
            })
            axios({
                method: 'get',
                url: `${ config.api.SERVER_URL }/content/author/${ currSess.getAccessToken().payload["username"] }/${ this.props.title }`
            }).then((response) => {
                this.setState({
                    content: response.data
                }, () => {
                    axios({
                        method: 'get',
                        url: `${ config.api.SERVER_URL }/content/author/${currSess.getAccessToken().payload["username"]}/${ this.props.title }/text`
                    }).then((response) => {
                        this.setState({
                            contentBody: response.data.text
                        })
                    }).catch(error => {
                        console.log(error.response);
                        if (error.response) {
                            this.setState({
                                error: "Unable to retrieve content text. Please try again at a later time."
                            });
                        }
                        else {
                            this.setState({
                                error: "Unable to content text. Please try again at a later time."
                            });
                        }
                    })
                })
            }).catch(error => {
                console.log(error.response);
                if (error.response) {
                    if (error.response.status == 404) {
                        // TODO: Redirect to 404 not found page
                        this.setState({
                            error: "Unable to retrieve content fields. Please try again at a later time."
                        });
                    }
                    else {
                        this.setState({
                            error: "Unable to retrieve content fields. Please try again at a later time."
                        });
                    }
                }
                else {
                    this.setState({
                        error: "Unable to content fields. Please try again at a later time."
                    });
                }
            })
        }
        catch (err) {
            // TODO: User is not logged in so redirect to sign in page
            this.setState({
                authError: AuthError.Unathenticated
            })
        }
    }

    handleSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let pages = this.state.contentBody.match(/(.|\n){1,4000}(\s|$)/g);
        let postBody = {
            ...this.state.content,
            body: pages
        };
        const currSess = await Auth.currentSession();
        this.setState({
            session: currSess
        });
        axios({
            method: 'put',
            url: `${ config.api.SERVER_URL }/content/author/${ currSess.getAccessToken().payload["username"] }/${ this.state.content.title }`,
            data: postBody,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then((response) => {
            this.setState({
                submitted: true,
            });
        }).catch(error => {
            console.log(error.response);
            if (error.response) {
                if (error.response.status == 400) {
                    this.setState({
                        submitError: error.response.data
                    })
                }
                else {
                    this.setState({
                        submitError: "Unable to submit form. Please try again at a later time."
                    });
                }
            }
            else {
                this.setState({
                    submitError: "Unable to submit form. Please try again at a later time."
                });
            }
        });
    }

    handleTitleField = (event:React.ChangeEvent<any>) => {
        let tempContent = {
            ...this.state.content,
            title: event.currentTarget.value
        }
        this.setState({
            content: tempContent
        })
    }

    handleDescriptionField = (event:React.ChangeEvent<any>) => {
        let tempContent = {
            ...this.state.content,
            description: event.currentTarget.value
        }
        this.setState({
            content: tempContent
        })
    }

    handleGenreField = (event:React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            let tempContent = {
                ...this.state.content,
                genre: [...this.state.content.genre, event.currentTarget.name]
            }
            this.setState({
                content: tempContent
            })
        }
        else {
            let tempContent = {
                ...this.state.content,
                genre: this.state.content.genre.filter((item) => item !== event.currentTarget.name)
            }
            this.setState({
                content: tempContent
            })
        }
    }

    handleContentField = (event:React.ChangeEvent<any>) => {
        this.setState({
            contentBody: event.currentTarget.value
        })
    }

    render() {
        if (this.state.authError === AuthError.Unathenticated) {
            return(
                <Redirect to="/login" />
            )
        }
        if (this.state.submitted || this.state.authError === AuthError.Forbidden) {
            return(
                <Redirect to="/myaccount" />
            )
        }
        return(
            <ContentItemUploadForm content={ this.state.content }
                contenBody={ this.state.contentBody }
                submitError={ this.state.submitError }
                handleTitleField={ this.handleTitleField }
                handleDescriptionField={ this.handleDescriptionField }
                handleGenreField={ this.handleGenreField }
                handleContentField={ this.handleContentField }
                handleSubmit={ this.handleSubmit }
            />
        )
    }
}