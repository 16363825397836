import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

const LoadingSpinner: React.FunctionComponent<{

}> = (props) => {
    return(
        <Container fluid >
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        </Container>
    )
}

export default LoadingSpinner;