import React, { Component } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Content } from '../common/types';
import StoryList from './StoryList';
import config from '../config';

type ContentDataState = {
    contentList: Content[];
    error: string;
};

type ContentDataProps = {
    size: number;
    contentLoadPath: string;
    viewMorePath: string;
    title: string;
};

export default class ContentData extends Component<ContentDataProps, ContentDataState> {
    constructor(props: ContentDataProps) {
        super(props);

        this.state = {
            contentList: [],
            error: ""
        };
    }

    componentDidMount() {
        axios({
            url: `${ config.api.SERVER_URL }/${ this.props.contentLoadPath }?size=${this.props.size}&skip=0`,
            method: 'get'
        }).then(response => {
            console.log(response);
            this.setState({
                contentList: response.data
            });
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            });
        });
    }

    render() {
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        return(
            <StoryList title={ this.props.title }
                contents={this.state.contentList}
                viewMorePath={ this.props.viewMorePath }
                isSaved={ false }
            />
        )
    }
}