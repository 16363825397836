import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

const VerifySignUpForm: React.FunctionComponent<{
    verifyError: string,
    handleVerificationField: (event:React.ChangeEvent<any>) =>void,
    handleVerification: (event: React.FormEvent<HTMLFormElement>) => void,
    handleResendVerification: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
    handleVerifyAlertClose: () => void,
    verifyShow: boolean
}> = (props) => {
    return(
        <Container>
            <Card>
                <Card.Body>
                    <Alert variant="success" onClose={ props.handleVerifyAlertClose } show={ props.verifyShow } dismissible>
                        <p>Verification code has been sent</p>
                    </Alert>
                    <Card.Title>Verify Signup</Card.Title>
                    { props.verifyError
                        ? <Alert variant={ 'danger' }>{ props.verifyError }</Alert>
                        : null
                    }
                    <Form className="justify-content-center" onSubmit={ props.handleVerification }>
                        <Form.Group >
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control type="text" onChange={ props.handleVerificationField }/>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                    <Card.Link href="" onClick={ props.handleResendVerification }>Resend Verification Code</Card.Link>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default VerifySignUpForm;