import React, { Component } from 'react';
import axios from 'axios';
import { Content, ViewMorePath } from '../common/types';
import StoryList from './StoryList';
import config from '../config';
import Alert from 'react-bootstrap/Alert';

type ContentDataState = {
    contentList: Content[];
    error: string;
}

type ContentDataProps = {
    authorName: string;
};

export default class AuthorContentData extends Component<ContentDataProps, ContentDataState> {
    constructor(props: ContentDataProps) {
        super(props);

        this.state = {
            contentList: [],
            error: ""
        };
    }

    componentDidMount() {
        axios({
            url: `${ config.api.SERVER_URL }/content/author/${ this.props.authorName }`,
            method: 'get'
        }).then(response => {
            console.log(response);
            this.setState({
                contentList: response.data
            });
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            });
        })
    }

    render() {
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        return(
            <StoryList title="Content"
                contents={ this.state.contentList }
                viewMorePath={ ViewMorePath.None }
                isSaved={ false }
            />
        )
    }
}