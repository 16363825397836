import * as React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import JumbotronBackground from '../assets/congruent_pentagon.png';

const jumbotronStyle = {
    backgroundImage: `url(${JumbotronBackground})`
}

const LandingPageJumbotron: React.FunctionComponent<{

}> = (props) => {
    return(
        <Container fluid >
            <Jumbotron fluid className="text-center" style={jumbotronStyle}>
                <h1>
                    Community created short stories!
                </h1>
                <p>
                    Write stories or read stories created by others.
                </p>
            </Jumbotron>
        </Container>
    )
}

export default LandingPageJumbotron;