import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { NavLink } from 'react-router-dom';

const SignOutCard: React.FunctionComponent<{

}> = (props) => {
    return(
        <Container>
            <Card className="justify-content-center">
                <Card.Body>
                    <Card.Title>Successfully Signed Out</Card.Title>
                    <NavLink to="/">Return to Landing Page</NavLink>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default SignOutCard;