import * as React from 'react';

import NavBarData from '../components/NavBarData';
import PageviewData from '../components/PageViewData';

const ContentViewPage: React.FunctionComponent<{
    author: string,
    title: string,
    page: number
}> = (props) => {
    return(
        <div>
            <NavBarData />
            <PageviewData author={ props.author } title={ props.title } page={ props.page } />
        </div>
    );
}

export default ContentViewPage;