import * as React from 'react'
import axios from 'axios'
import { Content } from '../common/types'
import StoryCardData from './StoryCardData'
import config from '../config'
import LoadingSpinner from './LoadingSpinner'
import Alert from 'react-bootstrap/Alert'

type ContentIDItemDataState = {
    content: Content
    isLoaded: boolean
    error: string
}

type ContentIDItemDataProps = {
    id: string
    handleContentNotFound: (id: string) => void
}

export default class ContentIDItemData extends React.Component<ContentIDItemDataProps, ContentIDItemDataState> {
    constructor(props: ContentIDItemDataProps) {
        super(props)

        this.state = {
            content: {
                id: "",
                author: "",
                title: "",
                description: "",
                genre: [],
                creation_date: 0,
                page_count: 0,
                body: []
            },
            isLoaded: false,
            error: ""
        }
    }

    componentDidMount() {
        // Get content from ID
        axios({
            url: `${ config.api.SERVER_URL }/content/id?id=${this.props.id}`,
            method: 'get'
        }).then(res => {
            this.setState({
                content: res.data,
                isLoaded: true
            })
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 404) {
                    this.setState({
                        isLoaded: true,
                        error: "The content has been deleted by the author. Attempting to remove item from list. Please refresh for changes."
                    });
                    this.props.handleContentNotFound(this.props.id)
                }
                else {
                    this.setState({
                        isLoaded: true,
                        error: "Unable to load data. Please try again at a later time."
                    })
                }
            }
            else {
                this.setState({
                    isLoaded: true,
                    error: "Unable to load data. Please try again at a later time."
                })
            }
        })
    }

    shouldComponentUpdate() {
        return true
    }

    render() {
        if (this.state.error !== "") {
            return(
                <Alert
                    variant={ 'danger' }
                    test-selector="error-message"
                    style={{ height:'25rem', marginTop:'2rem', marginBottom:'2rem' }}
                >
                    { this.state.error }
                </Alert>
            )
        }
        if (this.state.isLoaded) {
            return(
                <StoryCardData
                    content={ this.state.content }
                    isSaved={ true }
                />
            )
        }
        return(
            <LoadingSpinner />
        )
    }
}