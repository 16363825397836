import * as React from 'react';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { BaseContentItem } from '../common/types';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import bookmarkPlus from '../assets/bookmark-plus.svg';
import xCircle from '../assets/x-circle.svg';
import thumbsUpFill from '../assets/hand-thumbs-up-fill.svg';
import thumbsUp from '../assets/hand-thumbs-up.svg';

const StoryCard: React.FunctionComponent<{
    content: BaseContentItem,
    isSaved: boolean,
    isLiked: boolean,
    likeCount: number,
    message: string,
    error: string,
    handleBookmark: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void,
    handleUnbookmark: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void,
    handleLike: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void,
    handleUnlike: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void,
}> = (props) => {
    return(
        <Link to={ `/content/${props.content.author}/${props.content.title}/0` }
            style={{ textDecoration: 'none', color: '#000000'}}
            test-selector={`link-${props.content.author}-${props.content.title}`}
        >
            <Card border="dark"
                bg="light"
                style={{ height:'25rem', marginTop:'2rem', marginBottom:'2rem' }}
                test-selector={`card-${props.content.author}-${props.content.title}`} >
                <Card.Body>
                    <Alert variant={ 'info' }
                        show={ props.message !== "" }
                        test-selector="info-message"
                    >
                        { props.message }
                    </Alert>
                    <Alert variant={ 'danger' }
                        show={ props.error !== "" }
                        test-selector="error-message"
                    >
                        { props.error }
                    </Alert>
                    <Card.Title style={{ marginBottom: '.75rem'}}>{props.content.title}</Card.Title>
                    <Card.Subtitle className="text-muted"
                        style={{ marginBottom: '.5rem'}}>
                        <Link to={ `/content/${props.content.author}` }>{props.content.author}</Link>
                    </Card.Subtitle>
                    <Card.Text>
                        { props.content.description}
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    { props.isSaved ?
                        <Image src={ xCircle } 
                            style={{ height: '1.5rem', width: '1.5rem', float: 'left' }}
                            onClick={ props.handleUnbookmark }
                            test-selector={`unbookmark-${ props.content.author }-${ props.content.title}`}
                        /> :
                        <Image src={ bookmarkPlus } 
                            style={{ height: '1.5rem', width: '1.5rem', float: 'left' }}
                            onClick={ props.handleBookmark }
                            test-selector={`bookmark-${ props.content.author }-${ props.content.title}`}
                        />
                    }
                    {
                        props.isLiked ?
                        <Image src={ thumbsUpFill } 
                            style={{ height: '1.5rem', width: '1.5rem', float: 'left', marginLeft: '.5rem' }}
                            onClick={ props.handleUnlike }
                            test-selector={`unlike-${ props.content.author }-${ props.content.title}`}
                        /> :
                        <Image src={ thumbsUp } 
                            style={{ height: '1.5rem', width: '1.5rem', float: 'left', marginLeft: '.5rem' }}
                            onClick={ props.handleLike }
                            test-selector={`like-${ props.content.author }-${ props.content.title}`}
                        />
                    }
                    <Card.Text style={{ float: 'left', marginLeft: '.25rem'}}
                        test-selector={`likecount-${ props.content.author }-${ props.content.title }`}
                    >
                        { props.likeCount }
                    </Card.Text>
                </Card.Footer>
            </Card>
        </Link>
    );
}

export default StoryCard;