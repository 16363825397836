import * as React from 'react';
import { Content } from '../common/types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EditableContentItemData from './EditableContentItemData';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const EditableContentList: React.FunctionComponent<{
    contents: Content[];
    reloadList: () => void;
}> = (props) => {
    return(
        <Card>
            <Card.Header>
            <Button href="/myaccount/upload"
                test-selector="createContent"
            >
                Create
            </Button>
            </Card.Header>
            <Card.Body>
            <Container fluid>
                {props.contents.map(content => (
                    <Row>
                        <Col>
                            <EditableContentItemData content={ content } reloadList={ props.reloadList} />
                        </Col>
                    </Row>
                ))}
            </Container>
                </Card.Body>
        </Card>
    )
}

export default EditableContentList;