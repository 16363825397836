import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

const SignUpForm: React.FunctionComponent<{
    handleUsername: (event: React.ChangeEvent<any>) => void,
    handlePassword: (event: React.ChangeEvent<any>) => void,
    handleEmail: (event: React.ChangeEvent<any>) => void,
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}> = (props) => {
    return(
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title>Signup Form</Card.Title>
                    <Form className="justify-content-center" onSubmit={ props.handleSubmit }>
                        <Form.Group >
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" onChange={ props.handleEmail } />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="username" onChange={ props.handleUsername } />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" onChange={ props.handlePassword }/>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default SignUpForm;