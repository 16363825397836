import React, { Component } from 'react';
import ContentData from './ContentData';
import { APPROVED_GENRES, ContentLoadPath, ViewMorePath } from '../common/types';


type RandomGenreContainerDataState = {
    genreList: string[];
};

type RandomGenreContainerDataProps = {
    numberOfGenres: number;
};

export default class RandomGenreContainerData extends Component<RandomGenreContainerDataProps, RandomGenreContainerDataState> {
    constructor(props: RandomGenreContainerDataProps) {
        super(props);

        this.state = {
            genreList: []
        }
    }

    componentDidMount() {
        let result = getRandom(APPROVED_GENRES, this.props.numberOfGenres)
        this.setState({
            genreList: result
        })
    }

    render() {
        return(
            <div>
                { this.state.genreList.map(genre => (
                    <ContentData title={ genre } contentLoadPath= { `${ContentLoadPath.Genre}/${ genre }` } size={ 4 } viewMorePath={ `${ViewMorePath.Genre}/${ genre }` }/>
                ))}
            </div>
        );
    }
}

// Function for selecting random n elements from array
// https://stackoverflow.com/questions/19269545/how-to-get-a-number-of-random-elements-from-an-array
function getRandom(arr:string[], n:number) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}