import * as React from 'react';
import NavBarData from '../components/NavBarData';
import ContentScrollerData from '../components/ContentScrollerData';
import { v4 as uuidv4 } from 'uuid';

const ViewMoreContentPage: React.FunctionComponent<{
    title: string;
    contentLoadPath: string;
}> = (props) => {
    let uuid = uuidv4()
    return(
        <div>
            <NavBarData key={ uuid } />
            <ContentScrollerData key={ props.title } title={ props.title } contentLoadPath={ props.contentLoadPath } size={ 4 }/>
        </div>
    );
}

export default ViewMoreContentPage;