import React, { Component } from 'react';
import SignUpForm from './SignUpForm';
import { Auth } from 'aws-amplify';
import VerifySignUpFormData from './VerifySignUpFormData';

type FormDataState = {
    username: string,
    password: string,
    email: string,
    verify: boolean,
    verification: string,
    verified: boolean,
    verifyAlert: boolean
}

type FormDataProps = {
}

export default class SignUpFormData extends Component<FormDataProps, FormDataState> {
    constructor(props: FormDataProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            email: "",
            verify: false,
            verification: "",
            verified: false,
            verifyAlert: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsernameField = this.handleUsernameField.bind(this);
        this.handlePasswordField = this.handlePasswordField.bind(this);
        this.handleEmailField = this.handleEmailField.bind(this);
        this.handleVerifyReset = this.handleVerifyReset.bind(this);
    }

    handleSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await Auth.signUp({
                username: this.state.username,
                password: this.state.password,
                attributes: {
                    email: this.state.email
                }
            });
            this.setState({
                verify: true
            })
        } catch (err) {
            console.log({ err });
        }
    }

    handleUsernameField = (event:React.ChangeEvent<any>) => {
        this.setState({
            username: event.currentTarget.value
        })
    }

    handlePasswordField = (event:React.ChangeEvent<any>) => {
        this.setState({
            password: event.currentTarget.value
        })
    }

    handleEmailField = (event:React.ChangeEvent<any>) => {
        this.setState({
            email: event.currentTarget.value
        })
    }

    handleVerifyReset = () => {
        this.setState({
            verify: false
        })
    }

    render() {
        if (this.state.verify) {
            return(
                <VerifySignUpFormData username={ this.state.username } handleVerifyReset={ this.handleVerifyReset }/>
            )
        }
        return(
            <SignUpForm handleUsername={ this.handleUsernameField } handlePassword={ this.handlePasswordField } handleEmail={ this.handleEmailField } handleSubmit={ this.handleSubmit }/>
        )
    }
}