import React, { Component } from 'react';
import './App.css';
import LandingPage from './pages/LandingPage';
import ContentViewPage from './pages/ContentViewPage';
import AuthorContentPage from './pages/AuthorContentPage';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import SignOutpage from './pages/SignOutPage';
import ContentItemUploadPage from './pages/ContentItemUploadPage';
import MyAccountPage from './pages/MyAccountPage';
import ViewMoreContentPage from './pages/ViewMoreContentPage';
import GenrePage from './pages/GenrePage';
import AuthorEditPage from './pages/AuthorEditPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import NotFoundPage from './pages/NotFoundPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { ContentLoadPath } from './common/types';
import ContentItemUpdatePage from './pages/ContentItemUpdatePage';

type AppDataState = {
  user: any,
  authLoad: boolean
}

type AppDataProps = {

}

export default class App extends Component<AppDataProps, AppDataState> {
  constructor(props: AppDataProps) {
    super(props);

    this.state = {
      user: null,
      authLoad: false
    };
  }

  async componentDidMount() {
    const currUser = await Auth.currentUserInfo()
    this.setState({
      user: currUser,
      authLoad: true
    })
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/signup">
            <SignUpPage />
          </Route>
          <Route path="/signout">
            <SignOutpage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/myaccount/upload">
            <ContentItemUploadPage />
          </Route>
          <Route path="/myaccount/edit">
            <AuthorEditPage />
          </Route>
          <Route path="/myaccount" >
            <MyAccountPage authLoad={ this.state.authLoad } user={ this.state.user }/>
          </Route>
          <Route path="/forgot">
            <ForgotPasswordPage />
          </Route>
          <Route path="/content/:author/:title/update" children={ <UpdateItem /> } />
          <Route path="/content/:author/:title/:page" children={ <StoryItem /> } />
          <Route path="/content/:author" children={ <AuthorItem /> } />
          <Route path="/content" >
            <ViewMoreContentPage title="Content" contentLoadPath={ ContentLoadPath.Content }/>
          </Route>
          <Route path="/latest" >
            <ViewMoreContentPage title="Latest" contentLoadPath={ ContentLoadPath.Latest }/>
          </Route>
          <Route path="/genre/:genre" children={ <GenreItem /> } />
          <Route path="/genre" >
            <GenrePage />
          </Route>
          <Route path="/search" children={ <SearchItem /> } />
          <Route path="/notfound" >
            <NotFoundPage />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </Router>
    );
  }
}

function UpdateItem() {
  let {author, title } = useParams()
  return(
    <ContentItemUpdatePage author={ author } title={ title } />
  )
}

function StoryItem() {
  let { author, title, page } = useParams();
  return(
    <ContentViewPage author={ author }  title={ title } page={ page } />
  )
}

function AuthorItem() {
  let { author } = useParams();
  return(
    <AuthorContentPage authorName={ author }/>
  )
}

function GenreItem() {
  let { genre } = useParams();
  return(
    <ViewMoreContentPage title={ genre } contentLoadPath={ `${ ContentLoadPath.Genre }/${ genre }` }/>
  )
}

function SearchItem() {
  let query = useQuery()
  return(
    <ViewMoreContentPage title={ `Search results for ${ query.get("key") }` } contentLoadPath={ `${ ContentLoadPath.Search }${ query.get("key") }` }/>
  )
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}