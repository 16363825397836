import React, { Component } from 'react';
import axios from 'axios';
import { Author } from '../common/types';
import AuthorInfo from './AuthorInfo';
import config from '../config';
import { Redirect } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

type AuthorDataState = {
    author: Author;
    notFound: boolean;
    error: string;
}

type AuthorDataProps = {
    authorName: string;
    editable: boolean;
}

export default class AuthorData extends Component<AuthorDataProps, AuthorDataState> {
    constructor(props: AuthorDataProps) {
        super(props);

        this.state = {
            author: {
                username: "",
                description: "",
                picURL: ""
            },
            notFound: false,
            error: ""
        }
    }

    componentDidMount() {
        axios({
            url: `${ config.api.SERVER_URL }/author/${ this.props.authorName }`,
            method: 'get'
        }).then(response => {
            console.log(response);
            this.setState({
                author: response.data
            })
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 404) {
                    this.setState({
                        notFound: true
                    });
                }
                else {
                    this.setState({
                        error: "Unable to load data. Please try again at a later time."
                    })
                }
            }
            else {
                this.setState({
                    error: "Unable to load data. Please try again at a later time."
                })
            }
        })
    }

    render() {
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        if (this.state.notFound) {
            return(
                <Redirect push to="/notfound" />
            )
        }
        return(
            <AuthorInfo author={ this.state.author }
                editable={ this.props.editable }
            />
        )
    }
}