import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';


const AuthorEditForm: React.FunctionComponent<{
    username: string;
    description: string;
    cancelShow: boolean;
    submitError: string;
    handleDescriptionField: (event:React.ChangeEvent<any>) => void,
    handleSubmit: (event:React.FormEvent<HTMLFormElement>) => void,
    handleCancelButton: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    handleCancelConfirm: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}> =(props) => {
    return(
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title>Edit Profile</Card.Title>
                    { props.submitError
                        ? <Alert variant={ 'danger' }>{ props.submitError }</Alert>
                        : null
                    }
                    <Form onSubmit={ props.handleSubmit }>
                        <Form.Group>
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder={ props.username } readOnly/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea"
                                rows={10}
                                onChange={ props.handleDescriptionField }
                                defaultValue={ props.description }
                                test-selector="editAuthorDescription"
                            />
                        </Form.Group>

                        <Button variant="primary"
                            type="submit"
                            test-selector="buttonSubmit"
                        >
                            Update
                        </Button>
                        <Button variant="secondary" style={{ margin:'1rem' }} onClick={ props.handleCancelButton }>
                            Cancel
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <Modal show={ props.cancelShow } onHide={ props.handleCancelButton }>
                <Modal.Header closeButton>
                    <Modal.Title>Exit Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>There may be unsaved changes. Are you sure you want to exit?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ props.handleCancelButton }>Cancel</Button>
                    <Button variant="danger" onClick={ props.handleCancelConfirm }>Exit</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default AuthorEditForm;