import React, { Component } from 'react';
import axios from 'axios';
import { Content, ViewMorePath } from '../common/types';
import config from '../config';
import InfiniteScroll from 'react-infinite-scroller';
import Alert from 'react-bootstrap/Alert';
import StoryList from './StoryList';

type ContentScrollerDataState = {
    contentList: Content[];
    skip: number;
    hasMore: boolean;
    error: string;
};

type ContentScrollerDataProps = {
    title: string;
    size: number;
    contentLoadPath: string;
};

export default class ContentScrollerData extends Component<ContentScrollerDataProps, ContentScrollerDataState> {
    constructor(props: ContentScrollerDataProps) {
        super(props);

        this.state = {
            contentList: [],
            skip: 0,
            hasMore: true,
            error: ""
        };

        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    componentDidMount() {
        let querySymbol = '?'
        if ( this.props.contentLoadPath.includes("?")) {
            querySymbol = '&'
        }
        axios({
            url: `${ config.api.SERVER_URL }/${ this.props.contentLoadPath }${ querySymbol }size=${ this.props.size }&skip=0`,
            method: 'get'
        }).then(response => {
            console.log(response);
            this.setState({
                contentList: response.data,
                skip: this.state.skip + this.props.size
            });
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            });
        });
    }

    handleLoadMore() {
        let querySymbol = '?'
        if ( this.props.contentLoadPath.includes("?")) {
            querySymbol = '&'
        }
        axios({
            url: `${ config.api.SERVER_URL }/${ this.props.contentLoadPath }${ querySymbol }size=${ this.props.size }&skip=${ this.state.skip }`,
            method: 'get'
        }).then(response => {
            if (response.data.length === 0) {
                this.setState({
                    hasMore: false
                })
            }
            else {
                this.setState({
                    contentList: [...this.state.contentList, ...response.data],
                    skip: this.state.skip + this.props.size
                })
            }
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            });
        });
    }

    render() {
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        return(
            <InfiniteScroll
                pageStart={ 0 }
                loadMore={ this.handleLoadMore }
                hasMore={ this.state.hasMore }
                initialLoad={ true }
                //loader={}
            >
                <StoryList title={ this.props.title }
                    contents={ this.state.contentList }
                    viewMorePath={ ViewMorePath.None }
                    isSaved={ false }
                />
            </InfiniteScroll>
        )
    }
}