import * as React from 'react';
import { Auth } from 'aws-amplify';
import NavBar from './NavBar';
import { Redirect } from 'react-router-dom';

type NavBarDataState = {
    user: any,
    loggedIn: boolean,
    signedOut: boolean,
    searchKey: string,
    hasSearch: boolean
}

type NavBarDataProps = {
}

export default class NavBarData extends React.Component<NavBarDataProps, NavBarDataState> {
    constructor(props: NavBarDataProps) {
        super(props);

        this.state = {
            user: {},
            loggedIn: true,
            signedOut: false,
            searchKey: "",
            hasSearch: false
        };

        this.handlSignOut = this.handlSignOut.bind(this);
        this.handleSearchField = this.handleSearchField.bind(this);
        this.handleSearchButton = this.handleSearchButton.bind(this);
    }

    async componentDidMount() {
        const currUser = await Auth.currentUserInfo();
        this.setState({
            user: currUser,
            signedOut: false
        })
    }

    handlSignOut = async (event:React.MouseEvent<any>) => {
        event.preventDefault()
        try {
            await Auth.signOut();
            this.setState({
                signedOut: true
            })
        } catch (err) {
            console.log({ err });
        }
    }

    handleSearchField = (event:React.ChangeEvent<any>) => {
        this.setState({
            searchKey: event.currentTarget.value
        })
    }

    handleSearchButton = async (event:React.FormEvent<HTMLFormElement>) => {
        this.setState({
            hasSearch: true
        })
    }

    render() {
        let username = "Guest";
        let loggedIn = false;
        if (this.state.user != null) {
          username = this.state.user.username;
          loggedIn = true;
        }
        if (this.state.hasSearch) {
            return(
                <div>
                    <NavBar username={ username }
                        signOut={ this.handlSignOut }
                        isLoggedIn={ loggedIn }
                        handleSearchField={ this.handleSearchField }
                        handleSearchButton={ this.handleSearchButton }
                    />
                    <Redirect to={`/search?key=${ this.state.searchKey }`} />
                </div>
            )
        }
        if (this.state.signedOut) {
            return(
                <div>
                    <NavBar username=""
                        signOut={ this.handlSignOut }
                        isLoggedIn={ false }
                        handleSearchField={ this.handleSearchField }
                        handleSearchButton={ this.handleSearchButton }
                    />
                    <Redirect to="/signout" />
                </div>
            );
        }
        return(
            <div>
                <NavBar username={ username }
                    signOut={ this.handlSignOut }
                    isLoggedIn={ loggedIn }
                    handleSearchField={ this.handleSearchField }
                    handleSearchButton={ this.handleSearchButton }
                />
            </div>
        );
    }
}