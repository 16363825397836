let config = {
    api: {
        SERVER_URL: process.env.REACT_APP_SERVER_URL
    },
    cognito: {
        REGION: process.env.REACT_APP_AWS_REGION,
        USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL,
        APP_CLIENT_ID: process.env.REACT_APP_AWS_APP_CLIENT
    }
};

export default config;