import * as React from 'react';
import axios from 'axios';
import { BaseContentItem } from '../common/types';
import StoryCard from './StoryCard';
import { Auth } from 'aws-amplify';
import config from '../config';

type StoryCardDataState = {
    error: string;
    isSaved: boolean;
    isLiked: boolean;
    likeCount: number;
    message: string;
}

type StoryCardDataProps = {
    content: BaseContentItem;
    isSaved: boolean;
}

export default class StoryCardData extends React.Component<StoryCardDataProps, StoryCardDataState> {
    constructor(props: StoryCardDataProps) {
        super(props);

        this.state = {
            error: "",
            isSaved: this.props.isSaved,
            isLiked: false,
            likeCount: 0,
            message: ""
        };

        this.handleBookmark = this.handleBookmark.bind(this)
        this.handleUnbookmark = this.handleUnbookmark.bind(this)
        this.handleLike = this.handleLike.bind(this)
        this.handleUnlike = this.handleUnlike.bind(this)
    }

    componentDidMount() {
        // Get number of likes for a content
        axios({
            url: `${ config.api.SERVER_URL }/likes/content/count?id=${ this.props.content.id }`,
            method: 'get'
        }).then(res => {
            this.setState({
                likeCount: res.data.count
            })
        })

        // Check if content is liked and saved
        Auth.currentSession().then( sess => {
            axios({
                url: `${ config.api.SERVER_URL }/likes/content/me?id=${ this.props.content.id }`,
                method: 'get',
                headers: {
                    Authorization: 'Bearer ' + sess.getAccessToken().getJwtToken()
                }
            }).then(res => {
                this.setState({
                    isLiked: res.data.is_liked
                })
            }).catch(error => {
                this.setState({
                    error: "Unable to load like data. Please try again at a later time."
                })
            })

            axios({
                url: `${ config.api.SERVER_URL }/saved/content/me?id=${ this.props.content.id }`,
                method: 'get',
                headers: {
                    Authorization: 'Bearer ' + sess.getAccessToken().getJwtToken()
                }
            }).then(res => {
                this.setState({
                    isSaved: res.data.is_saved
                })
            }).catch(error => {
                this.setState({
                    error: "Unable to load saved data. Please try again at a later time."
                })
            })
        })
    }

    shouldComponentUpdate() {
        return true
    }

    handleBookmark = async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        event.preventDefault()
        let currSess = null;
        try {
            currSess = await Auth.currentSession();
        }
        catch (err) {
            this.setState({
                message: "Requires Login"
            }, ()=> {
                window.setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 1000);
            });
            return
        }
        axios({
            method: 'put',
            url: `${ config.api.SERVER_URL}/saved/content/saved`,
            data: this.props.content,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then(() => {
            this.setState({
                isSaved: true,
                message: "Saved"
            }, ()=> {
                window.setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 1000);
            });
        }).catch( error => {
            this.setState({
                error: "Unable to save content. Please try again at a later time."
            });
        });
    }

    handleUnbookmark = async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        event.preventDefault()
        try{
            const currSess = await Auth.currentSession();
            axios({
                method: 'delete',
                url: `${ config.api.SERVER_URL}/saved/content/saved`,
                data: this.props.content,
                headers: {
                    Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
                }
            }).then(() => {
                this.setState({
                    isSaved: false,
                    message: "Unsaved"
                }, ()=> {
                    window.setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 1000);
                });
            });
        } catch(err) {
            this.setState({
                error: "Unable to unsave content. Please try again at a later time."
            });
        }
    }

    handleLike = async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        event.preventDefault()
        let currSess = null;
        try {
            currSess = await Auth.currentSession();
        }
        catch (err) {
            console.log(err);
            this.setState({
                message: "Requires Login"
            }, ()=> {
                window.setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 1000);
            });
            return
        }
        axios({
            method: 'put',
            url: `${ config.api.SERVER_URL }/likes/content/like`,
            data: this.props.content,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then(() => {
            this.setState(prevState => ({
                isLiked: true,
                likeCount: prevState.likeCount + 1
            }))
        }).catch( error => {
            this.setState({
                error: "Unable to like content. Please try again at a later time."
            })
        })
    }

    handleUnlike = async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        event.preventDefault()
        let currSess = null;
        try {
            currSess = await Auth.currentSession();
        }
        catch (err) {
            console.log(err);
            this.setState({
                message: "Requires Login"
            }, ()=> {
                window.setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 1000);
            });
            return
        }
        axios({
            method: 'delete',
            url: `${ config.api.SERVER_URL }/likes/content/like`,
            data: this.props.content,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then(() => {
            this.setState(prevState => ({
                isLiked: false,
                likeCount: prevState.likeCount - 1
            }))
        }).catch( error => {
            this.setState({
                error: "Unable to unlike content. Please try again at a later time."
            })
        })
    }

    render() {
        // TODO: Determine where to show error
        return(
            <StoryCard
                content={ this.props.content }
                isSaved={ this.state.isSaved }
                isLiked={ this.state.isLiked }
                likeCount={ this.state.likeCount }
                message={ this.state.message }
                error={ this.state.error }
                handleBookmark={ this.handleBookmark }
                handleUnbookmark={ this.handleUnbookmark }
                handleLike={ this.handleLike }
                handleUnlike={ this.handleUnlike }
            />
        )
    }
}