import React, { Component } from 'react';
import axios from 'axios';
import { Page, Content } from '../common/types';
import StoryText from './StoryText';
import config from '../config';
import Alert from 'react-bootstrap/Alert';
import { Redirect } from 'react-router-dom';

type PageViewDataState = {
    contentItem: Content
    pageItem: Page
    notFound: boolean
    error: string
}

type PageViewDataProps = {
    author: string,
    title: string,
    page: number
}

export default class PageviewData extends React.Component<PageViewDataProps, PageViewDataState> {
    constructor(props: PageViewDataProps) {
        super(props);

        this.state = {
            pageItem: {
                text: ""
            },
            contentItem: {
                id: "",
                author: "",
                title: "",
                description: "",
                genre: [],
                creation_date: 0,
                page_count: 0,
                body: []
            },
            notFound: false,
            error: ""
        }
    }

    componentDidMount() {
        var contentUrl: string = `${ config.api.SERVER_URL }/content/author/` + this.props.author + "/" + this.props.title
        axios({
            url: contentUrl,
            method: 'get'
        }).then(response => {
            this.setState({
                contentItem: response.data
            });
        }).catch(error => {
            if (error.response) {
                if (error.response.status == 404) {
                    this.setState({
                        notFound: true
                    });
                }
                else {
                    this.setState({
                        error: "Unable to load data. Please try again at a later time."
                    })
                }
            }
            else {
                this.setState({
                    error: "Unable to load data. Please try again at a later time."
                })
            }
        });
        var pageUrl: string = contentUrl + "/pages/" + this.props.page 
        axios({
            url: pageUrl,
            method: 'get'
        }).then(response => {
            this.setState({
                pageItem: response.data
            });
        }).catch(error => {
            if (error.response) {
                if (error.response.status == 404) {
                    this.setState({
                        notFound: true
                    });
                }
                else {
                    this.setState({
                        error: "Unable to load data. Please try again at a later time."
                    })
                }
            }
            else {
                this.setState({
                    error: "Unable to load data. Please try again at a later time."
                })
            }
        });
    }

    render() {
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        if (this.state.notFound) {
            return(
                <Redirect push to="/notfound" />
            )
        }
        return(
            <StoryText author={ this.state.contentItem.author } title={ this.state.contentItem.title } text={ this.state.pageItem.text } page={ this.props.page } pageCount={ this.state.contentItem.page_count }/>
        )
    }
}