import * as React from 'react';
import NavBarData from '../components/NavBarData';
import AuthorData from '../components/AuthorData';
import AuthorContentData from '../components/AuthorContentData';

const AuthorContentPage: React.FunctionComponent<{
    authorName: string
}> = (props) => {
    return(
        <div>
            <NavBarData />
            <AuthorData authorName={ props.authorName }
                editable={ false }
            />
            <AuthorContentData authorName={ props.authorName } />
        </div>
    )
}

export default AuthorContentPage;