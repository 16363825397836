import * as React from 'react';
import NavBarData from '../components/NavBarData';
import { APPROVED_GENRES, ContentLoadPath, ViewMorePath } from '../common/types';
import ContentData from '../components/ContentData';

const GenrePage: React.FunctionComponent<{

}> = (props) => {
    return(
        <div>
            <NavBarData />
            { APPROVED_GENRES.map( genre => ( 
                <ContentData title={ genre } contentLoadPath= { `${ContentLoadPath.Genre}/${ genre }` } size={ 4 } viewMorePath={ `${ViewMorePath.Genre}/${ genre }` }/>
            ))}
        </div>
    )
}

export default GenrePage;