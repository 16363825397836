import * as React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

const LoginForm: React.FunctionComponent<{
    handleUsername: (event: React.ChangeEvent<any>) => void,
    handlePassword: (event: React.ChangeEvent<any>) => void,
    handleLogin: (event: React.FormEvent<HTMLFormElement>) => void,
    errorMessage: string
}> = (props) => {
    let showAlert = false;
    if (props.errorMessage !== "") {
        showAlert = true;
    }
    return(
        <Container>
            <Card>
                <Card.Body>
                    <Card.Title>Login</Card.Title>
                        <Alert variant={ 'danger' } show={ showAlert }>
                            { props.errorMessage }
                        </Alert>
                        <Form className="justify-content-center" style={{ marginBottom: '2rem' }} onSubmit={ props.handleLogin }>
                            <Form.Group >
                                <Form.Label>Username</Form.Label>
                                <Form.Control id="username" type="username" onChange={ props.handleUsername }/>
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Password</Form.Label>
                                <Form.Control id="password" type="password" onChange={ props.handlePassword }/>
                                <Link id="forgot" to="/forgot">Forgot Password?</Link>
                            </Form.Group>
                            
                            <Button variant="primary"
                                type="submit"
                                test-selector="loginSubmit"
                            >
                                Submit
                            </Button>
                        </Form>
                        <Card.Text>Don't have an account? <Link id="signup" to="/signup">sign up here</Link></Card.Text>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default LoginForm;