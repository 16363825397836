import * as React from 'react';
import NavBarData from '../components/NavBarData';
import AuthorEditFormData from '../components/AuthorEditFormData';

const AuthorEditPage: React.FunctionComponent<{

}> = (props) => {
    return(
        <div>
            <NavBarData />
            <AuthorEditFormData/>
        </div>
    )
}

export default AuthorEditPage;