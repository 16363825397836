import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

const NavBar: React.FunctionComponent<{
    username: string,
    isLoggedIn: boolean,
    signOut: (event:React.MouseEvent<any>) => void,
    handleSearchField: (event:React.ChangeEvent<any>) => void,
    handleSearchButton: (event:React.FormEvent<HTMLFormElement>) => void
}> = (props) => {
    return(
        <Navbar bg="dark" variant="dark" expand='md'>
            <Navbar.Brand  href="" test-selector='brand'>LoreWeave</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse>
                <Nav className="mr-auto">
                    <Nav.Link href="/" test-selector='home'>Home</Nav.Link>
                    <Nav.Link href="/genre" test-selector='genre'>Genres</Nav.Link>
                </Nav>
                <Form inline onSubmit={ props.handleSearchButton }>
                    <FormControl type="text"
                        placeholder="Search"
                        className="mr-sm-2"
                        onChange={ props.handleSearchField }
                        test-selector="search"
                    />
                    <Button variant="outline-info"
                        type="submit"
                        test-selector="searchSubmit"
                    >
                        Search
                    </Button>
                </Form>
                {   props.isLoggedIn ?
                    <AccountMenu username={ props.username }
                        signOut={ props.signOut }
                    /> :
                    <Link to="/login"
                        test-selector="login"
                    >
                        Log in
                    </Link>
                }
            </Navbar.Collapse>
        </Navbar>
    )
}

const AccountMenu: React.FunctionComponent<{
    username: string,
    signOut: (event:React.MouseEvent<any>) => void
}> = (props) => {
    return(
        <Nav>
            <NavDropdown title={ props.username } 
                id="collapsible-nav-dropdown"
                test-selector="accountDropdown"
                alignRight
            >
                <NavDropdown.Item 
                    href="/myaccount"
                    test-selector="account"
                >
                    Account
                </NavDropdown.Item>
                <NavDropdown.Item onClick={ props.signOut }
                    test-selector="signOut"
                >
                    Sign Out
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    )
}

export default NavBar;