import * as React from 'react';
import SignUpFormData from '../components/SignUpFormData';
import NavBarData from '../components/NavBarData';

const SignUpPage: React.FunctionComponent<{

}> = (props) => {
    return(
        <div>
            <NavBarData />
            <SignUpFormData />
        </div>
    )
}

export default SignUpPage;