import * as React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Content } from '../common/types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

const EditableContentItem: React.FunctionComponent<{
    content: Content;
    likeCount: number;
    deleteConfirm: boolean;
    deleteError: string;
    handleConfirmDeleteShow: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleContentItemDeletion: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = (props) => {
    return(
        <>
            <Accordion>
                <Card bg="light">
                    <Accordion.Toggle as={ Card.Header }
                        eventKey={ props.content.title }
                        test-selector={ `item-${ props.content.title }` }
                    >
                        <p>{ props.content.title }</p>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={ props.content.title }>
                        <Card.Body>
                            <Card.Text test-selector={ `likecount-${ props.content.title }` }>
                                { `Likes: ${ props.likeCount }` }
                            </Card.Text>
                            <Button variant="primary"
                                href={`/content/${props.content.author}/${props.content.title}/0`}
                                style={{ marginRight: '0.5rem'}}
                                test-selector={ `view${ props.content.title }` }
                            >
                                    View
                            </Button>
                            <Button variant="primary"
                                href={ `/content/${ props.content.author }/${ props.content.title }/update` }
                                style={{ marginRight: '0.5rem'}}
                                test-selector={ `update${ props.content.title }` }
                            >
                                Update
                            </Button>
                            <Button variant="danger"
                                onClick={ props.handleConfirmDeleteShow }
                                test-selector={ `delete${ props.content.title }` }
                            >
                                Delete
                            </Button>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Modal show={ props.deleteConfirm } onHide={ props.handleConfirmDeleteShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { props.deleteError
                        ? <Alert variant={ 'danger' }>{ props.deleteError }</Alert>
                        : null
                    }
                    <p>Are you sure you want to delete { props.content.title }</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={ props.handleConfirmDeleteShow }
                    >
                        Cancel
                    </Button>
                    <Button variant="danger"
                        onClick={ props.handleContentItemDeletion }
                        test-selector="confirmDeletion"
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditableContentItem;