import * as React from 'react';
import NavBarData from '../components/NavBarData';
import ContentItemUpdateFormData from '../components/ContentItemUpdateFormData';

const ContentItemUpdatePage: React.FunctionComponent<{
    author: string,
    title: string,
}> = (props) => {
    return(
        <div>
            <NavBarData />
            <ContentItemUpdateFormData author={ props.author }
                title={ props.title }
            />
        </div>
    )
}

export default ContentItemUpdatePage;