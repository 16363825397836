import * as React from 'react';
import NavBarData from '../components/NavBarData';
import ForgotPasswordFormData from '../components/ForgotPasswordFormData';

const ForgotPasswordPage: React.FunctionComponent<{

}> = (props) => {
    return(
        <div>
            <NavBarData />
            <ForgotPasswordFormData />
        </div>
    )
}

export default ForgotPasswordPage;