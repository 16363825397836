import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';

const StoryText: React.FunctionComponent<{
    author: string,
    title: string,
    text: string,
    page: number,
    pageCount: number
}> = (props) => {
    let paginationItems = [];
    let nextDisabled = false;
    let prevDisabled = false;

    // Load all pagination pages if 7 or less pages
    if (props.pageCount < 8) {
        for (let number = 0; number < props.pageCount; number++) {
            let link = "/content/" + props.author + "/" + props.title + "/" + number;
            console.log(typeof props.page)
            paginationItems.push(
                <Pagination.Item key={ number } active={ number === Number(props.page) } href={ link }>
                    { number }
                </Pagination.Item>
            )
        }
    }
    // Determine where to put ellipse
    else {
        let pageNumbers:number[] = [];
        //Create array of pages to load
        if (Number(props.page) < 4 ) {
            pageNumbers = [0, 1, 2, 3, 4,  -1, Number(props.pageCount) - 1]
        }
        else if (Number(props.page) > Number(props.pageCount) - 5) {
            pageNumbers = [0, -1, Number(props.pageCount) - 5, Number(props.pageCount) - 4, Number(props.pageCount) - 3,  Number(props.pageCount) - 2, Number(props.pageCount) - 1]
        }
        else {
            pageNumbers = [0, -1, Number(props.page) - 1, Number(props.page), Number(props.page) + 1, -1, Number(props.pageCount) - 1]
        }
        pageNumbers.forEach( (pageItem) => {
            if (pageItem !== -1) {
                paginationItems.push(
                    <Pagination.Item key={ pageItem }
                        active={ pageItem === Number(props.page) }
                        href={ `/content/${props.author}/${props.title}/${pageItem}` }
                    >
                        { pageItem }
                    </Pagination.Item>
                )
            }
            else {
                paginationItems.push(
                    <Pagination.Ellipsis disabled />
                )
            }
        })
    }

    if (Number(props.page) === 0) {
        prevDisabled = true;
    }
    if (Number(props.page) === props.pageCount - 1) {
        nextDisabled = true;
    }
    let prevPage = Number(props.page) - 1
    let nextPage = Number(props.page) + 1
    return(
        <Container>
            <Card className="justify-content-center">
                <Card.Header >
                    <p className="text-center">{ props.title }</p>
                </Card.Header>
                <Card.Body>
                    <Card.Text style={{ whiteSpace: "pre-line"}}
                        test-selector="contentText"
                    >
                        { props.text }
                    </Card.Text>
                    <Pagination className="justify-content-center">
                        <Pagination.Prev disabled={ prevDisabled }
                            href={ `/content/${props.author}/${props.title}/${prevPage}` }
                        />
                        { paginationItems }
                        <Pagination.Next disabled={ nextDisabled }
                            href={ `/content/${props.author}/${props.title}/${nextPage}` }
                        />
                    </Pagination>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default StoryText;