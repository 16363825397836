import * as React from 'react';
import NavBarData from '../components/NavBarData';
import EditableContentListData from '../components/EditableContentListData';
import SavedContentListData from '../components/SavedContentListData';
import AuthorData from '../components/AuthorData';
import LoadingSpinner from '../components/LoadingSpinner';
import { Redirect } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


const MyAccountPage: React.FunctionComponent<{
    authLoad: boolean,
    user: any
}> = (props) => {
    if ( !props.authLoad ) {
        return(
            <LoadingSpinner />
        )
    }
    if (props.user != null) {
        return(
            <div>
                <NavBarData />
                <AuthorData authorName={ props.user.username }
                    editable={ true }
                />
                <Tabs defaultActiveKey="saved"
                    id="uncontrolled-tabs"
                >
                    <Tab eventKey="saved" 
                        title="Saved"
                        test-selector="savedTab"
                    >
                        <SavedContentListData />
                    </Tab>
                    <Tab eventKey="created"
                        title="Created"
                        test-selector="createTab"
                    >
                        <EditableContentListData username={ props.user.username }/> 
                    </Tab>
                </Tabs>
            </div>
        )
    }
    return(
        <Redirect to="/login" />
    )
}

export default MyAccountPage;