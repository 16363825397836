import axios from 'axios'
import * as React from 'react'
import { SavedContentItem } from '../common/types'
import { Auth } from 'aws-amplify'
import ContentIDList from './ContentIDList'
import Alert from 'react-bootstrap/Alert'
import config from '../config'

const CONTENT_PER_PAGE = 12

type SavedContentListDataState = {
    savedContentList: SavedContentItem[]
    currentPage: number
    pageCount: number
    error: string
}

type SavedContentListDataProps = {

}

export default class SavedContentListData extends React.Component<SavedContentListDataProps, SavedContentListDataState> {
    constructor(props: SavedContentListDataProps) {
        super(props)

        this.state = {
            savedContentList: [],
            pageCount: 0,
            currentPage: 0,
            error: ""
        }

        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleContentNotFound = this.handleContentNotFound.bind(this)
    }

    async componentDidMount() {
        const currSess = await Auth.currentSession()
        axios({
            method: 'get',
            url: `${ config.api.SERVER_URL }/saved/content/me`,
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        }).then(response => {
            let savedListTemp:SavedContentItem[] = response.data
            let pageCountTemp: number = (Math.floor(savedListTemp.length/CONTENT_PER_PAGE)) + 1
            this.setState({
                savedContentList: savedListTemp,
                pageCount: pageCountTemp,
                currentPage: 0
            })
        }).catch(error => {
            this.setState({
                error: "Unable to load data. Please try again at a later time."
            })
        })
    }

    shouldComponentUpdate() {
        return true
    }

    handlePageChange = (event: any) => {
        if (event.target.text) {
            if (event.target.text.includes("Next")) {
                this.setState(prevState => ({
                    currentPage: prevState.currentPage + 1
                }))
            }
            else if (event.target.text.includes("Previous")) {
                this.setState(prevState => ({
                    currentPage: prevState.currentPage - 1
                }))
            }
            else {
                this.setState({
                    currentPage: Number(event.target.text)
                })
            }
        }
    }

    handleContentNotFound = async (id: string) => {
        const currSess = await Auth.currentSession()
        axios({
            method: 'delete',
            url: `${ config.api.SERVER_URL}/saved/content/saved`,
            data: {
                id: id
            },
            headers: {
                Authorization: 'Bearer ' + currSess.getAccessToken().getJwtToken()
            }
        })
    }

    render() {
        if (this.state.error) {
            return(
                <Alert variant={ 'danger'}>
                    { this.state.error }
                </Alert>
            )
        }
        let savedContent:SavedContentItem[] = []
        if (this.state.pageCount != 0) {
            let begin = CONTENT_PER_PAGE * this.state.currentPage
            let end = begin + CONTENT_PER_PAGE
            savedContent = this.state.savedContentList.slice(begin, end)
        }
        return(
            <ContentIDList
                savedContentList={ savedContent }
                pageCount={ this.state.pageCount }
                page={ this.state.currentPage }
                handlePageChange={ this.handlePageChange }
                handleContentNotFound={ this.handleContentNotFound }
            />
        )
    }
}