export interface BaseContentItem {
    id: string;
    author: string;
    title: string;
    description: string;
}

export type Content = {
    id: string;
    author: string;
    title: string;
    description: string;
    genre: string[];
    creation_date: number;
    page_count: number;
    body: string[];
}

export type SavedContentItem = {
    content_id: string;
    user_saved: string;
    is_saved: boolean;
}

export type Page = {
    text: string;
}

export type Author = {
    username: string,
    description: string,
    picURL: string
}

export type User = {
    username: string,
    description: string,
    picURL: string,
}

export enum ContentLoadPath {
    Content = "content",
    Latest = "content/latest",
    Genre = "content/genre",
    Search = "content/search?key="
}

export enum ViewMorePath {
    None = "",
    Content = "content",
    Latest = "latest",
    Genre = "genre"

}

export const APPROVED_GENRES = [
    "Action",
	"Drama",
	"Fantasy",
	"Horror",
	"Mystery",
	"Romance",
	"Science Fiction",
	"Western",
]

export enum AuthError {
    None = "none",
    Unathenticated = "unauthenticated",
    Forbidden = "forbidden"
}